import { connect } from 'react-redux';

import DiscoverInterests from '../components/DiscoverInterests';

const mapStateToProps = (state) => {
  const {
    assessment: {
      results: {
        interests,
      },
    },
  } = state;

  return {
    interests,
  };
};

const mapDispatchToProps = () => ({});

const DiscoverInterestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverInterests);

export default DiscoverInterestsContainer;
