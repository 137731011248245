import React from 'react';
import styled from 'styled-components';

import {
  Colors,
  H1Template,
} from '../../components';

const H2 = styled.h2`
  ${H1Template}
  white-space: nowrap;
  margin: 0 .5rem;
  color: ${({ color }) => (color)};
`;

const Line = styled.div`
  display: flex;
  flex: 1 1 auto;
  border-top: 1px solid black;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
`;

const GraphTitle = ({ active }) => (
  <Title>
    <H2>
      YOUR
      <span style={{ color: active === "values" ? Colors.red : Colors.lightGrey }}> VALUES</span>
    </H2>
    <Line />
    <H2 color={active === "interests" ? Colors.blue : Colors.lightGrey}>
      INTERESTS
    </H2>
    <Line />
    <H2 color={active === "abilities" ? Colors.green : Colors.lightGrey}>
      ABILITIES
    </H2>
  </Title>
);

export default GraphTitle;
