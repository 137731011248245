import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { chain } from 'lodash';

import {
  H1 as RawH1,
  Colors,
  MediumButton as Button,
  XButton,
  Icons,
  Modal,
} from '../../components';

const Content = styled.div`
  background-color: ${Colors.white};
  padding: 0rem 2rem 2rem;
  margin: 0;

  border: 1px solid ${Colors.grey};
  border-top: none;
  border-radius: 0 0 15px 15px;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const JobListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;

  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
  max-width: 1400px;
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;

const H1 = styled(RawH1)`
  margin: 0;
`;

const P = styled.p`
  margin: 1rem 0;
  padding: 0;
`;

const QuickP = styled.p`
  margin: 0;
  padding: 0;
`;

const JobTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: baseline;
`;

const JobTitle = styled.p`
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: .5;
  }
`;

const JobContainer = styled.div`
  flex: 1 1 auto;
  width: 425px;
  padding: .25rem;
  margin: 0;
`;

const JobChecked = styled.input`
  margin-right: .25rem;
`;

const JobDescription = styled.div`
  position: absolute;
  padding: 0 .5rem .5rem 1.25rem;
  margin: .25rem 0;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  width: 400px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

const XButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  justify-content: end;
  align-items: center;
  padding-top: 0rem;
  padding-right: 0.25rem;
`;

const StyledXButton = styled(XButton)`
  padding: 0px;
  margin-left: auto;
`;

const StyledStartButton = styled.button`
  color: ${Colors.yellow};
  background-color: ${Colors.white};
  border: none;
  cursor: pointer;
  &:hover {
    opacity: .5;
  }
  text-decoration: none;
`;

const StarButton = ({
  isFavorite,
  onClick,
}) => (
  <StyledStartButton
    onClick={onClick}
  >
    {
      isFavorite
        ? <Icons.Star />
        : <Icons.EmptyStar />
    }
  </StyledStartButton>
);

const Job = ({
  j,
  setShowJob,
  showJob,
  toggleFavorite,
}) => (
  <JobContainer
    key={`job_${j.id}`}
  >
    <JobTitleContainer>
      <JobChecked
        type="checkbox"
        checked={j.favoriteJob !== null && j.favoriteJob !== undefined}
        onChange={() => toggleFavorite(j)}
      />
      <JobTitle
        key={`job_${j.id}`}
        onClick={() => {
          if (showJob === j.id) {
            setShowJob(undefined);
          } else {
            setShowJob(j.id);
          }
        }}
      >
        {showJob === j.id ? <b>{j.jobTitle}</b> : j.jobTitle}
      </JobTitle>
    </JobTitleContainer>
    {
      showJob === j.id
        ? (
          <JobDescription>
            <XButtonContainer>
              <b>{j.jobTitle}</b>
              <StarButton
                isFavorite={j.favoriteJob !== null && j.favoriteJob !== undefined}
                onClick={() => {
                  toggleFavorite(j);
                }}
              />
              <StyledXButton
                onClick={() => setShowJob(undefined)}
              />
            </XButtonContainer>
            <QuickP>
              { j.description }
              &nbsp;
              <Link
                to={`/job/${j.id}`}
              >
                Read More
              </Link>
            </QuickP>
          </JobDescription>
        )
        : ''
    }
  </JobContainer>
);

const Cluster = React.forwardRef(({
  id,
  abilities,
  scales,
  subClusters,
  jobs: inJobs,
  color,
  name,
  favoriteJobs: allFavoriteJobs,
  description,
  toggleFavorite: inToggleFavorite,
  nextCluster,
  showJobSorter,
  lastCluster,
}, ref) => {
  const [ showJob, setShowJob ] = useState();
  const [ isOpen, setIsOpen ] = useState(null);

  const favoriteJobs = allFavoriteJobs.filter((j) => {
    if (j.clusterId && j.clusterId === id) {
      return true;
    }
    if (!j.clusters) {
      return false;
    }
    if (j.clusters.length === 0) {
      return false;
    }
    // not sure if this vestigial or not
    const hasCluster = j.clusters.filter((clust) => (
      clust.id === id
    ));
    return hasCluster.length > 0;
  });

  const toggleFavorite = (j) => {
    // We check to see if we should open the pop-up
    // We have to add/subtract 1 because the length doesn't update until after
    // this function ends
    if (j.favoriteJob === null && favoriteJobs.length + 1 >= 5) {
      setIsOpen(true);
    } else if (favoriteJobs.length - 1 >= 5) {
      setIsOpen(true);
    }
    inToggleFavorite(j);
  };

  let divSubClusters;
  if (subClusters && subClusters.length > 0) {
    divSubClusters = subClusters.map((sub) => {
      const jobs = sub.jobs.map((j) => (
        <Job
          key={`job_${j.id}`}
          j={j}
          showJob={showJob}
          setShowJob={setShowJob}
          toggleFavorite={toggleFavorite}
        />
      ));
      return (
        <div key={`subCluster_${sub.id}`}>
          <h2>{sub.name}</h2>
          <JobListContainer>
            {jobs}
          </JobListContainer>
        </div>
      );
    });
  }
  if (inJobs && inJobs.length > 0) {
    divSubClusters = (
      <JobListContainer>
        {
          inJobs.map((j) => (
            <Job
              j={j}
              showJob={showJob}
              setShowJob={setShowJob}
              toggleFavorite={toggleFavorite}
            />
          ))
        }
      </JobListContainer>
    );
  }
  const yourValues = chain(scales.values)
    .map((scale) => (
      scale.match ? scale[scale.direction] : ''
    ))
    .compact()
    .value();

  return (
    <Content
      ref={ref}
      key={`render_cluster_${id}`}
    >
      <H1 color={color}>{name}</H1>
      <P>{description}</P>
      <P>
        People in this cluster typically value the following:&nbsp;
        {
          scales.values.map((scale) => (
            scale[scale.direction]
          )).join(', ')
        }.
      </P>
      {
        yourValues.length > 0 ? (
          <P>
            You value: { yourValues.join(', ') }
          </P>
        ) : ''
      }
      <P>
        Jobs in this cluster require strong ability in the following areas:&nbsp;
        {
          scales.abilities.map((scale) => (
            scale.name
          )).join(', ')
        }.
      </P>
      {
        abilities > 0 ? (
          <P>You have strong ability in this cluster.</P>
        ) : ''
      }
      {
        favoriteJobs.length < 3 ? (
          <P>Please select at least 3 jobs from this cluster.</P>
        ) : ''
      }
      {divSubClusters}
      <ButtonContainer>
        <Button
          disabled={showJobSorter && allFavoriteJobs.length < 2}
          onClick={() => nextCluster(showJobSorter ? 'sortJobs' : 'nextCluster')}
        >
          {showJobSorter ? 'SORT JOBS' : 'NEXT CLUSTER'}
        </Button>
        {
          /* Only show the job sorter button if there are more than 1 favorite jobs */
          !showJobSorter ? (
            <Button
              link
              onClick={() => nextCluster('sortJobs')}
            >
              Sort Jobs
            </Button>
          ) : ''
        }
        {
          showJobSorter && !lastCluster ? (
            <Button
              link
              onClick={() => nextCluster('nextCluster')}
            >
              I would like to look at more clusters.
            </Button>
          ) : ''
        }
      </ButtonContainer>
      <Modal
        isOpen={isOpen !== undefined && isOpen !== null && isOpen !== false}
        parentSelector={() => document.querySelector('#content')}
        title="Great Job!"
      >
        <p>You have selected five or more jobs. Would you like to look at your next cluster?</p>
        <Button
          onClick={() => {
            setIsOpen(false);
            nextCluster('nextCluster');
          }}
          style={{
            alignSelf: 'center',
          }}
        >
          Next Cluster
        </Button>
        <Button
          link
          onClick={() => {
            setIsOpen(false);
          }}
        >
          I would like to keep looking.
        </Button>
      </Modal>
    </Content>
  );
});

Cluster.propTypes = {
  /** The id of the cluster */
  id: PropTypes.number.isRequired,
  /** The color of the cluster */
  color: PropTypes.string.isRequired,
  /** The name of the cluster */
  name: PropTypes.string.isRequired,
  /** A description of the cluster */
  description: PropTypes.string.isRequired,
  /** An array of favoriteJobs */
  favoriteJobs: PropTypes.arrayOf(PropTypes.shape({
    favoriteJob: PropTypes.number,
    clusterId: PropTypes.number,
  })),
  /** A function called when the user clicks on the favorite job checkbox */
  toggleFavorite: PropTypes.func.isRequired,
  /** The scales related to this cluster */
  scales: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      match: PropTypes.bool,
    })),
    abilities: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  /** The examinee's ability ranked score for this cluster. (0 or 3) */
  abilities: PropTypes.number,
  /** A callback function to move onto the next cluster */
  nextCluster: PropTypes.func.isRequired,
  /** If true will switch text to SORT JOBS */
  showJobSorter: PropTypes.bool,
  /** If true will disable looking at additional clusters */
  lastCluster: PropTypes.bool,
};

Cluster.defaultProps = {
  scales: {
    values: [],
    abilities: [],
  },
  abilities: 0,
  favoriteJobs: [],
  showJobSorter: false,
  lastCluster: false,
};

export default Cluster;
