import React, { useRef } from 'react';
import styled from 'styled-components';

import {
  Colors,
  AbilitiesGraph,
  MediumButton as Button,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const DiscoverAbilities = ({
  abilities,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  /*
  const strong = chain(abilities.ability)
    .filter((a) => (a.norm >= 4))
    .map((c) => c.name.toUpperCase())
    .value();
  */
  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="abilities" />
      <AbilitiesGraph
        abilities={abilities}
        width={width}
      />
      <p>
        This is placeholder text for the DiscoverAbilities component.
        Should we display the abilities graph that shows cut off scores?
      </p>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/interests">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/fullgraph">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverAbilities;
