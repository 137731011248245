import { connect } from 'react-redux';
import {
  chain,
  each,
  filter,
} from 'lodash';

import DiscoverClusters from '../components/DiscoverClusters';

import {
  loadClusters,
} from '../../actions/clusterActions';
import {
  toggleFavoriteJob,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    assessment: {
      results: {
        clusters,
      },
    },
    cluster: {
      clusters: allClusters,
      subClusters: allSubClusters,
    },
    jobs: {
      obj: allJobs,
      favorites: allFavoriteJobs,
    },
    user: {
      profile: user,
    },
  } = state;

  // join the clusters, sub clusters, and jobs together
  const allFullClusters = {};
  each(allClusters, (clust) => {
    const subClusters = chain(allSubClusters)
      .filter({ clusterId: clust.id })
      .map((sub) => ({
        ...sub,
        jobs: filter(allJobs, { subClusterId: sub.id }),
      }))
      .value();

    allFullClusters[clust.id] = {
      ...clust,
      subClusters,
      // if it's professional, we don't have subclusters
      jobs: subClusters.length === 0 ? filter(allJobs, { clusterId: clust.id }) : undefined,
    };
  });

  return {
    user,
    allClusters: allFullClusters,
    clusters,
    favoriteJobs: allFavoriteJobs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadClusters: (clusters, favoritesFor) => (dispatch(loadClusters(clusters, favoritesFor))),
  toggleFavoriteJob: (params) => (dispatch(toggleFavoriteJob(params))),
});

const DiscoverClustersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverClusters);

export default DiscoverClustersContainer;
