import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import styled from 'styled-components';

import * as Colors from './Colors';
import UpperCaseMessage from './UpperCaseMessage';
import * as Icons from './icons';

import * as globalMessages from '../../utils/GlobalMessages';
import {
  useResize,
} from '../../utils/sizing';

const widths = {
  page: 1400,
  menu: {
    expanded: 345,
    closed: 115,
  },
  rightGutter: 0,
  caretTotal: 40,
  min: 500,
};

const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: ${(props) => (
    props.$expanded
      ? `${widths.menu.expanded}px`
      : `${widths.menu.closed}px`
  )};
  width: ${(props) => (props.$expanded ? '345px' : 'auto')};
  background-color: ${Colors.white};
  border-right: 1px solid ${Colors.grey};
`;

const computeColor = (props) => {
  if (props.active === props.type) {
    return Colors.blue;
  }
  switch (props.type) {
    case 'explore':
      if (props.active === 'discover' || props.active === 'succeed') {
        return Colors.grey;
      }
      return Colors.lightGrey;
    case 'discover':
      if (props.active === 'succeed') {
        return Colors.grey;
      }
      return Colors.lightGrey;
    case 'succeed':
      return Colors.lightGrey;
    default:
      return Colors.lightGrey;
  }
};

const MenuItem = styled(Link)`
  color: ${(props) => computeColor(props)} !important;
  svg {
    fill: ${(props) => computeColor(props)} !important;
  }
  
  height: 106px;
  border-bottom: 1px solid ${Colors.grey};

  font-size: 28px;
  font-weight: 500;
  font-family: anisette-std, sans-serif;

  justify-content: ${(props) => (props.$expanded ? 'normal' : 'center')};

  display:flex;
  align-items:center;

  text-decoration:none;

  &:visted {
    color: ${(props) => computeColor(props)};
  }
  &:hover {
    opacity: ${(props) => (props.active ? '1' : '.5')};
  }

  padding: 0px .5rem;
`;

const MenuItemText = styled.span`
  min-width: 200px;
  padding-right: .5em;
`;

const FooterArea = styled.div`
  margin-top: auto;
  text-align: center;
  padding: .5em;
`;

const MenuCaret = styled.button`
  border: none;
  background: transparent;
  cursor: ew-resize;
  display: flex;
  padding-top: 7rem;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.background};
  width: 100%;
  flex: 1 1 auto;
`;

const Content = styled.div`
  display: flex;
  flex: 2 2 auto;
  flex-direction: column;
  ${(props) => {
    /**
     * case1: it's all too small
     * case2: no left gutter
     * case3: both sides have gutters
     */

    /**
     * Don't restrict the width if we're less than the max
     */
    if (props.$inWidth < widths.page) {
      return `max-width: ${props.$inWidth < 582 ? '582' : props.inWidth}px;`;
    }

    const menuTotal = widths.caretTotal + (
      props.$expanded ? widths.menu.expanded : widths.menu.closed
    );
    const contentAndGutters = props.$inWidth - menuTotal;
    if (contentAndGutters >= widths.page) {
      // If we have enough space in the left gutter, move it left to center
      const gutter = (contentAndGutters - widths.page) / 2;
      const menuOffset = menuTotal / 2;
      if (gutter - menuOffset > 0) {
        return `
          max-width: ${widths.page - menuOffset}px;
          left: -${menuOffset}px;
        `;
      }
      // Otherwise, it will off center
      return `
        max-width: ${widths.page - menuOffset}px;
      `;
    }
    return `max-width: ${contentAndGutters}px;`;
  }}
  margin: 0 auto;
  position: relative;
`;

const Reset = styled.div`
  display: flow-root;
`;

const Padding = styled.div`
  ${(props) => {
    if (props.$inWidth < widths.page) {
      return 'margin-right: 1rem';
    }
    return '';
  }}
`;

const ExamineeMenuComponent = ({
  assigned,
  showChangeProduct,
  showAdmin,
  expanded,
  setExpanded,
  id,
  children,
  clickExplore,
  clickDiscover,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const location = useLocation();
  let active = '';
  if (location.pathname.includes('/dashboard/examinee/explore')) {
    active = 'explore';
  }
  if (location.pathname.includes('/dashboard/examinee/discover')) {
    active = 'discover';
  }
  if (location.pathname.includes('/dashboard/examinee/succeed')) {
    active = 'succeed';
  }
  if (location.pathname.includes('/jobsearch')) {
    active = 'succeed';
  }
  return (
    <MenuWrapper ref={ref}>
      <LeftMenu
        $expanded={expanded}
      >
        <MenuItem
          type="explore"
          active={active}
          onClick={() => clickExplore()}
          to="/dashboard/examinee/explore"
        >
          {
            expanded && (
              <MenuItemText>
                <UpperCaseMessage
                  {...globalMessages.explore}
                />
              </MenuItemText>
            )
          }
          <Icons.Explore />
        </MenuItem>
        <MenuItem
          type="discover"
          active={active}
          onClick={() => clickDiscover()}
          to="/dashboard/examinee/discover"
        >
          {
            expanded && (
              <MenuItemText>
                <UpperCaseMessage
                  {...globalMessages.discover}
                />
              </MenuItemText>
            )
          }
          <Icons.Discover />
        </MenuItem>
        <MenuItem
          type="succeed"
          active={active}
          to="/dashboard/examinee/succeed"
        >
          {
            expanded && (
              <MenuItemText>
                <UpperCaseMessage
                  {...globalMessages.succeed}
                />
              </MenuItemText>
            )
          }
          <Icons.Succeed />
        </MenuItem>
        {
          expanded && (
            <FooterArea>
              {
                showChangeProduct && (
                  <small>
                    <FormattedMessage
                      id="examineeMenu.using"
                      defaultMessage="Using product from:"
                    />
                    <br />
                    {assigned && assigned.createdAt ? assigned.createdAt.toLocaleString(DateTime.DATE_SHORT) : ''}
                    <br />
                    <Link to="/dashboard/examinee/change-product">
                      <FormattedMessage
                        id="examineeMenu.changeProduct"
                        defaultMessage="Change Product"
                      />
                    </Link>
                  </small>
                )
              }
              {
                showAdmin && (
                  <>
                    <br />
                    <small>
                      <Link to="/dashboard/admin">
                        Switch to Admin View
                      </Link>
                    </small>
                  </>
                )
              }
            </FooterArea>
          )
        }
      </LeftMenu>
      <MenuCaret onClick={() => setExpanded(!expanded)}>
        {
          expanded
            ? <Icons.LeftArrow />
            : <Icons.RightArrow />
        }
      </MenuCaret>
      <Content
        id={id}
        $expanded={expanded}
        $inWidth={width}
      >
        <Reset>
          <Padding>
            {children}
          </Padding>
        </Reset>
      </Content>
    </MenuWrapper>
  );
};

ExamineeMenuComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  showChangeProduct: PropTypes.bool,
  showAdmin: PropTypes.bool,
};

ExamineeMenuComponent.defaultProps = {
  location: {},
  showChangeProduct: false,
  showAdmin: false,
};

export default ExamineeMenuComponent;
