import { connect } from 'react-redux';

import DiscoverJobSorter from '../components/JobSorter';

import {
  updateFavoriteJob,
  toggleFavoriteJob,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    jobs: {
      favorites: favoriteJobs,
    },
  } = state;

  return {
    favoriteJobs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleFavoriteJob: (params) => (dispatch(toggleFavoriteJob(params))),
  updateFavoriteJob: (params) => (dispatch(updateFavoriteJob(params))),
});

const DiscoverJobSorterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverJobSorter);

export default DiscoverJobSorterContainer;
