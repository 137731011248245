import { connect } from 'react-redux';
import DiscoverFullGraph from '../components/DiscoverFullGraph';

const mapStateToProps = (state) => {
  const {
    assignedProduct: {
      activeProduct,
    },
    assessment: {
      results: {
        clusters: results,
      },
    },
  } = state;

  let type;
  if (activeProduct.professional) {
    type = 'professional';
  } else if (activeProduct.language === 'spanish') {
    type = 'spanish';
  } else {
    type = 'cluster';
  }

  return {
    type,
    rankedClusters: results.ranked,
    results: state.assessment.results,
  };
};

const mapDispatchToProps = () => ({});

const DiscoverFullGraphContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverFullGraph);

export default DiscoverFullGraphContainer;
