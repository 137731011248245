import React from 'react';
import { Outlet } from 'react-router-dom';

import Root from './Root';

import {
  // AdminRoute,
  AssessmentRoute,
  CallBack,
  PrivateRoute,
  ProtectedAssessmentRoute,
  SignedOut,
  SuperRoute,
  Signin,
  Signout,
  Signup,
} from './auth';

import {
  Dashboard,
  ExamineeChangeProduct,
  SetupWelcome,
  SetupPurchase,
  SetupPurchaseComplete,
  SetupUser,
  EditProfile,
  EditPassword,
} from './dashboard';

/*
import {
  AdminDashboard,
  Examinees,
  Products,
  AddExaminee,
  AddAdmin,
  AdminExaminee,
  Admins,
  AddGroup,
  AdminProfile,
} from './admin';
*/

import {
  Explore as ExploreDashboard,
} from './explore';

import {
  Discover as DiscoverDashboard,
  Values as DiscoverValues,
  Interests as DiscoverInterests,
  Abilities as DiscoverAbilities,
  FullGraph as DiscoverFullGraph,
  Clusters as DiscoverClusters,
  JobSorter as DiscoverJobSorter,
} from './discover';

import {
  Succeed as SucceedDashboard,
  JobSearch,
  PublicSucceed,
} from './succeed';

import {
  NoRoute,
  JobDetails,
  Colors,
  StepperProvider,
} from './components';

import {
  InterestsDirections,
  InterestsAssessment,
  InterestsComplete,
  FinishAssessment,
  ValuesDirections,
  ValuesAssessment,
  ValuesComplete,
  Abilities,
  AbilitiesComplete,
  Timeout,
  MrDirections,
  MrAssessment,
  SrDirections,
  SrAssessment,
  VrDirections,
  VrAssessment,
  NaDirections,
  NaAssessment,
  LuDirections,
  LuAssessment,
  WkDirections,
  WkAssessment,
  PsaDirections,
  PsaAssessment,
  MsdDirections,
  MsdAssessment,
} from './assessments';

//const Stub = () => (<h1>Stub</h1>);

const AbilityAssessment = ({
  directions,
  assessment,
  type,
  ipp,
}) => ([
  {
    path: 'directions',
    element: (
      <AssessmentRoute
        type={type}
      >
        {directions}
      </AssessmentRoute>
    ),
  },
  {
    path: "finish",
    element: (
      <AssessmentRoute
        type={type}
      >
        <FinishAssessment
          ipp={ipp}
          skipResults
          color={Colors.green}
        />
      </AssessmentRoute>
    ),
  },
  {
    path: ":page",
    element: (
      <AssessmentRoute
        type={type}
      >
        {assessment}
      </AssessmentRoute>
    ),
  },
]);

const Routes = [
  {
    element: <Root />,
    children: [
      {
        path: '/callback',
        element: <CallBack />,
      },
      {
        path: '/signin',
        element: <Signin />,
      },
      {
        path: '/signout',
        element: <Signout />,
      },
      {
        path: '/signed-out',
        element: <SignedOut />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        element: <PrivateRoute><Outlet /></PrivateRoute>,
        children: [
          {
            path: '/',
            element: <Dashboard />,
          },
          {
            path: '/dashboard/setup',
            element: (<SetupWelcome />),
          },
          {
            path: '/dashboard/setup/purchase',
            element: (<SetupPurchase />),
          },
          {
            path: '/dashboard/setup/purchase-complete',
            element: (<SetupPurchaseComplete />),
          },
          {
            path: '/dashboard/setup/bio',
            element: (<SetupUser />),
          },
          {
            path: "/dashboard/examinee/change-product",
            element: <ExamineeChangeProduct />,
          },
          {
            path: "/profile",
            element: <EditProfile />,
          },
          {
            path: "/profile/password",
            element: <EditPassword />,
          },
          {
            path: "/job/:id",
            element: <JobDetails />,
          },
          {
            path: "/jobsearch/:jobTitle",
            element: <JobSearch />,
          },
          {
            path: "/dashboard/examinee/explore",
            element: <ExploreDashboard />,
          },
        ],
      },
      {
        path: "/dashboard/examinee/discover/*",
        element: <PrivateRoute noScroll><DiscoverDashboard /></PrivateRoute>,
        children: [
          {
            path: "",
            element: (
              <DiscoverValues />
            ),
          },
          {
            path: "interests",
            element: (<DiscoverInterests />),
          },
          {
            path: "abilities",
            element: (<DiscoverAbilities />),
          },
          {
            path: "fullgraph",
            element: (<DiscoverFullGraph />),
          },
          {
            path: "cluster/*",
            element: (<DiscoverClusters />),
          },
          {
            path: "jobs",
            element: (<DiscoverJobSorter />),
          },
        ],
      },
      {
        path: "/dashboard/examinee/succeed/*",
        element: <PrivateRoute noScroll><SucceedDashboard /></PrivateRoute>,
      },
      {
        path: "/results/:assignedProductId",
        element: <PublicSucceed />,
      },
      {
        path: "/assessment/values/*",
        children: [
          {
            path: "start",
            element: <ProtectedAssessmentRoute><ValuesDirections /></ProtectedAssessmentRoute>,
          },
          {
            path: "finish",
            element: (
              <AssessmentRoute type="values">
                <FinishAssessment
                  requireAllItems
                  ipp={7}
                  color={Colors.red}
                />
              </AssessmentRoute>
            ),
          },
          {
            path: "complete",
            element: <PrivateRoute><ValuesComplete /></PrivateRoute>,
          },
          {
            path: ":page",
            element: <AssessmentRoute type="values"><ValuesAssessment /></AssessmentRoute>,
          },
        ],
      },
      {
        path: "/assessment/interests/*",
        children: [
          {
            path: "start",
            element: <ProtectedAssessmentRoute><InterestsDirections /></ProtectedAssessmentRoute>,
          },
          {
            path: "finish",
            element: (
              <AssessmentRoute type="interests">
                <FinishAssessment
                  requireAllItems
                  color={Colors.blue}
                />
              </AssessmentRoute>
            ),
          },
          {
            path: "complete",
            element: <PrivateRoute><InterestsComplete /></PrivateRoute>,
          },
          {
            path: ":page",
            element: (
              <AssessmentRoute
                type="interests"
              >
                <InterestsAssessment />
              </AssessmentRoute>
            ),
          },
        ],
      },
      {
        path: "/assessment/abilities/*",
        children: [
          {
            path: "start",
            element: <ProtectedAssessmentRoute><Abilities /></ProtectedAssessmentRoute>,
          },
          {
            path: "complete",
            element: <PrivateRoute><AbilitiesComplete /></PrivateRoute>,
          },
          {
            path: "timeout",
            element: <PrivateRoute><Timeout /></PrivateRoute>,
          },
          {
            path: "mr/*",
            children: AbilityAssessment({
              directions: <MrDirections />,
              assessment: <MrAssessment />,
              type: "mr",
              ipp: 4,
            }),
          },
          {
            path: "sr/*",
            children: AbilityAssessment({
              directions: <SrDirections />,
              assessment: <SrAssessment />,
              type: "sr",
              ipp: 4,
            }),
          },
          {
            path: "vr/*",
            children: AbilityAssessment({
              directions: <VrDirections />,
              assessment: <VrAssessment />,
              type: "vr",
              ipp: 1,
            }),
          },
          {
            path: "na/*",
            children: AbilityAssessment({
              directions: <NaDirections />,
              assessment: <NaAssessment />,
              type: "na",
              ipp: 4,
            }),
          },
          {
            path: "lu/*",
            children: AbilityAssessment({
              directions: <LuDirections />,
              assessment: <LuAssessment />,
              type: "lu",
              ipp: 4,
            }),
          },
          {
            path: "wk/*",
            children: AbilityAssessment({
              directions: <WkDirections />,
              assessment: <WkAssessment />,
              type: "wk",
              ipp: 4,
            }),
          },
          {
            path: "psa/*",
            children: AbilityAssessment({
              directions: <PsaDirections />,
              assessment: <PsaAssessment />,
              type: "psa",
              ipp: 40,
            }),
          },
          {
            path: "msd/*",
            children: [
              {
                path: "directions",
                element: <AssessmentRoute type="msd"><MsdDirections /></AssessmentRoute>,
              },
              {
                path: "assessment",
                element: <AssessmentRoute type="msd"><MsdAssessment /></AssessmentRoute>,
              },
            ],
          },
        ],
      },
      /*
      {
        path: "/dashboard/admin",
        element: <AdminRoute><AdminDashboard /></AdminRoute>,
      },
      {
        path: "/admin/*",
        element: <AdminRoute><Outlet /></AdminRoute>,
        children: [
          {
            path: "examinees",
            element: <Examinees />,
          },
          {
            path: "examinee/add",
            element: (
              <StepperProvider key="addExaminee">
                <AddExaminee />
              </StepperProvider>
            ),
          },
          {
            path: "examinee/:id",
            element: <AdminExaminee />,
          },
          {
            path: "admin/add",
            element: (
              <StepperProvider key="addAdmin">
                <AddAdmin />
              </StepperProvider>
            ),
          },
          {
            path: "group/add",
            element: (
              <StepperProvider key="addGroup">
                <AddGroup />
              </StepperProvider>
            ),
          },
          {
            path: "admins",
            element: <Admins />,
          },
          {
            path: "adminProfile/:id",
            element: <AdminProfile />,
          },
          {
            path: "products",
            element: <Products />,
          },
        ],
      },
      */
      {
        path: "/super/*",
        element: <SuperRoute><Outlet /></SuperRoute>,
        children: [
          {
            path: "dashboard",
            lazy: () => (
              import('./super/index')
                .then((mods) => ({
                  Component: mods.SuperDashboard,
                }))
            ),
          },
          {
            path: "examinees",
            lazy: () => (
              import('./super/index')
                .then((mods) => ({
                  Component: mods.Examinees,
                }))
            ),
          },
          {
            path: "examinee/add",
            lazy: () => (
              import('./super/index')
                .then(({ AddExaminee }) => ({
                  element: (
                    <StepperProvider key="superAddExaminee">
                      <AddExaminee />
                    </StepperProvider>
                  ),
                }))
            ),
          },
          {
            //:view can be 'responses' or 'results'
            // defaults to 'results'
            path: "examinee/:id/:view",
            lazy: () => (
              import('./super/index')
                .then((mods) => ({
                  Component: mods.ExamineeProfile,
                }))
            ),
          },
          {
            path: "settings",
            lazy: () => (
              import('./super/index')
                .then((mods) => ({
                  Component: mods.Settings,
                }))
            ),
          },
        ],
      },
      {
        path: '/super/qbo-success',
        lazy: () => (
          import('./super/index')
            .then((mods) => ({
              Component: mods.QboSuccess,
            }))
        ),
      },
      {
        path: "*",
        element: <NoRoute />,
      },
    ],
  },
];

export default Routes;
