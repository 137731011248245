import React, { useRef } from 'react';
import styled from 'styled-components';

import { chain } from 'lodash';

import {
  Colors,
  ValuesGraph,
  MediumButton as Button,
} from '../../components';

import GraphTitle from './GraphTitle';

import {
  useResize,
} from '../../utils/sizing';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const RedText = styled.span`
  color: ${Colors.red};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
`;

const ScaleDic = {
  accepting: 'You prefer to tackle situations with tried-and-true methods rather than experimenting on the fly. You tend to be most productive in steady, quiet work environments.',
  investigative: '"Why?" is one of your favorite questions. You thrive at work when you are able to gather information to put towards novel solutions to complex problems.',
  carefree: 'You appreciate a lighthearted, imaginative approach to work. You’d rather spend time thinking about new ways to tackle problems than tidying up your workspace.',
  practical: 'You value an efficient workplace and have a deep appreciation for keeping things moving forward in an organized manner.',
  conformity: 'When you’re at work, you flourish under clear-cut guidelines and parameters. Your best work gets done when you know exactly what you’re working with.',
  independence: 'You prefer working to your own standards and on your own time. You flourish in flexible work environments where “open to interpretation” can be taken to heart.',
  supportive: 'You don’t feel the need to bark orders at people. You are happy working towards a collective goal without the bureaucratic and social pressures attached to roles in leadership.',
  leadership: 'You thrive when you’re allowed to step up, delegate tasks, and make final decisions. You often find yourself taking the reins in large group situations.',
  flexibility: 'Sometimes too much planning just gets in the way. You would rather have more time to complete a task in a flexible manner than less time after carefully planning what to do. You value the freedom to tackle your work as it comes.',
  orderliness: 'You value work environments where tasks are outlined well in advance and everything you need is readily accessible. Executing a thoughtfully-designed plan brings you true satisfaction.',
  privacy: 'The work you complete is more important than the recognition you might receive later. You prefer to stay out of the spotlight, focusing instead on a job well done.',
  recognition: 'At work, everyone knows who you are. Being acknowledged for a job well done empowers you to work even harder. You value your own accomplishments, and your peers value you.',
  realistic: 'You tend to strive for “function over form.” You can appreciate art, but not necessarily when you’re at work. Utility and practicality are amongst your top workplace priorities.',
  aesthetic: 'The phrase “art for art’s sake” rings true. You understand that art can often bring value to the workplace. Your favorite tasks involve the ability to incorporate an artistic sensibility.',
  reserved: 'Your best work gets done when you can hone in on individual projects and tend to your own affairs. You understand the value of teamwork, but independence will always be your preference.',
  social: 'At work, you hit your stride alongside others. It doesn’t matter if you’re collaborating, offering help, or collaborating to offer help – teamwork invigorates you.',
};

const DiscoverValues = ({
  values,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const {
    percentile: percentiles,
    conversion: conversions,
  } = values;
  const ranked = chain(Array(conversions.length))
    .map((sc, i) => ({
      ...conversions[i],
      norm: undefined,
      scaleType: undefined,
      rawScore: parseInt(conversions[i].rawScore, 10),
      conversion: conversions[i].norm,
      percentile: percentiles[i].norm,
    }))
    .sortBy((a) => (-Math.abs(a.conversion)))
    .map((sc) => {
      if (sc.conversion <= 0) {
        return sc.left;
      }
      return sc.right;
    })
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle
        active="values"
      />
      <ValuesGraph
        values={values}
        width={width}
      />
      <p>
        Your score on each value is represented by a bar. The further the bar
        is from the middle, the higher your score is on that trait. Your three
        strongest traits are highlighted in <RedText>RED</RedText>.
      </p>
      <p>
        Specifically, the bars represent your work values as compared to
        everyone who has completed this assessment. The further a score is from
        the middle, the further it is from the average response for that trait.
      </p>
      <p>
        To read more about the values themselves, click here.
      </p>
      <p>
        You value careers that are:
      </p>
      <p>
        <RedText>{ranked[0].toUpperCase()}</RedText>
      </p>
      <p>{ScaleDic[ranked[0].toLowerCase()]}</p>
      <p>
        <RedText>{ranked[1].toUpperCase()}</RedText>
      </p>
      <p>{ScaleDic[ranked[1].toLowerCase()]}</p>
      <p>
        <RedText>{ranked[2].toUpperCase()}</RedText>
      </p>
      <p>{ScaleDic[ranked[2].toLowerCase()]}</p>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/interests">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverValues;
