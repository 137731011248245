import React from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

const ChartLabel = styled.g`
  font-size: 1rem;
`;

// TODO: Make this load from the server
const percentiles = ['0', '2', '8', '17', '32', '50', '68', '83', '92', '98'];

const AbilitiesGraph = ({
  abilities,
  width: inWidth,
}) => {
  const {
    ability,
  } = abilities;

  let width = inWidth;
  if (inWidth === 0) {
    width = 500;
  }

  const margin = {
    left: rem(16),
    right: rem(1),
    top: rem(1),
    bottom: rem(1),
  };
  const barHeight = rem(1.45);
  const barPadding = 0.2;
  const height = ability.length * (barHeight + rem(barPadding));
  const delay = 0;
  const duration = 1000;

  const x = d3
    .scaleLinear()
    .domain([0, 9])
    .range([margin.left, width - margin.right]);

  const y = d3
    .scaleBand()
    .domain(ability.map((s) => (s.name)))
    .rangeRound([margin.bottom, height - margin.top])
    .padding(barPadding);

  const topAxis = d3.axisTop(x);
  const bottomAxis = d3.axisBottom(x).tickFormat((d) => (percentiles[d]));
  const yAxis = d3.axisLeft(y).tickSize(0);

  const G = ability.map((c) => (
    <g
      key={`graph_${c.ranking}`}
    >
      <rect
        ref={(node) => {
          d3
            .select(node)
            .transition()
            .delay(delay)
            .ease(d3.easeLinear)
            .duration(duration)
            .attr('width', x(c.norm) - x(0));
        }}
        x={x(0)}
        y={y(c.name)}
        width={0}
        height={y.bandwidth()}
        fill={Colors.green}
      />
      <text
        ref={(node) => {
          d3
            .select(node)
            .transition()
            .delay(delay)
            .duration(duration)
            .attr(
              'opacity',
              1,
            );
          if (node) {
            const bbox = node.getBBox();
            if (x(c.norm) - bbox.width - rem(0.25) > margin.left) {
              d3
                .select(node)
                .attr(
                  'x',
                  x(c.norm)
                  - bbox.width
                  - rem(0.25),
                )
                .style(
                  'fill',
                  Colors.white,
                );
            } else {
              d3
                .select(node)
                .attr(
                  'x',
                  x(0),
                )
                .style(
                  'fill',
                  Colors.grey,
                );
            }
          }
        }}
        opacity="0"
        x={x(c.norm)}
        y={y(c.name) + rem(0.889)}
      >
        {c.norm}
      </text>
    </g>
  ));

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      <g
        ref={(node) => d3.select(node).call(topAxis)}
        transform={`translate(0, ${margin.top})`}
      />
      <ChartLabel
        ref={(node) => {
          d3
            .select(node)
            .call(yAxis)
            .select('.domain')
            .attr('display', 'none');
        }}
        transform={`translate(${margin.left},0)`}
      />
      {G}
      <g
        ref={(node) => d3.select(node).call(bottomAxis)}
        transform={`translate(0, ${height - margin.bottom})`}
      />
    </svg>
  );
};

export default AbilitiesGraph;
