import React, { useRef } from 'react';
import styled from 'styled-components';

import { chain } from 'lodash';

import {
  Colors,
  InterestsGraph,
  MediumButton as Button,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const BlueText = styled.span`
  color: ${Colors.blue};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const DiscoverInterests = ({
  interests,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const top3 = chain(interests)
    .sortBy((a) => (a.norm))
    .reverse()
    .take(3)
    .map((c) => c.name.toUpperCase())
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="interests" />
      <InterestsGraph
        interests={interests}
        width={width}
      />
      <p>Your are intrested in
        &nbsp;<BlueText>{top3[0]}</BlueText>,
        &nbsp;<BlueText>{top3[1]}</BlueText>, and
        &nbsp;<BlueText>{top3[2]}</BlueText>.
      </p>
      <p>
        This is placeholder text for the DiscoverInterests component.
      </p>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/abilities">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverInterests;
