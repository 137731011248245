import { connect } from 'react-redux';

import DiscoverValues from '../components/DiscoverValues';

const mapStateToProps = (state) => {
  const {
    assessment: {
      results: {
        values,
      },
    },
  } = state;

  return {
    values,
  };
};

const mapDispatchToProps = () => ({});

const DiscoverValuesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverValues);

export default DiscoverValuesContainer;
