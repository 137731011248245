/* eslint react-hooks/exhaustive-deps:0, react/forbid-prop-types:0, react/jsx-no-useless-fragment:0
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useNavigate,
  Outlet,
} from 'react-router-dom';

import {
  H1 as RawH1,
  SmallButton as Button,
  CenteredSpinner,
  ExamineeMenu,
} from '../../components';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredButton = styled(Button)`
  margin: auto;
`;

const Content = styled.div`
  margin-right: .1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const H1 = styled(RawH1)`
  margin: 1rem;
`;

const P = styled.p`
  margin: 1rem 1rem 1rem 1rem;
`;

const Loading = () => (
  <>
    <H1>Please Wait</H1>
    <CenteredSpinner />
  </>
);

const DiscoverComponent = ({
  activeProduct,
  allFinished,
  rankedClusters,
  loadResults,
  loading,
  unloadResults,
  manualDiscover,
  suppressed,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    loadResults(activeProduct).then((data) => {
      if (data && data.jobs && data.jobs.length >= 3 && !manualDiscover) {
        navigate('/dashboard/examinee/succeed', { replace: true });
      }
    });
    return () => {
      unloadResults();
    };
  }, []);

  if (!loading && rankedClusters.ranked.length === 0) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>You have no results. Please finish an assessment.</p>
            <CenteredButton
              to="/dashboard/examinee/explore"
            >
              Back
            </CenteredButton>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  if (loading || rankedClusters.ranked.length === 0) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <div>
            <Loading />
          </div>
        </ExamineeMenu>
      </>
    );
  }

  if (!allFinished) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>You must finish all your assessments before you can Discover careers.</p>
            <CenteredButton
              to="/dashboard/examinee/explore"
            >
              Back
            </CenteredButton>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  if (suppressed) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>
              Your administrator has not released your results. Please contact
              them to receive your results.
            </p>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  return (
    <>
      <ExamineeMenu
        assigned={activeProduct}
        id="content"
      >
        <Content>
          <H1>DISCOVER YOUR CAREER PROFILE</H1>
          <P>
            It is time to discover careers based on your Values, Interests, and
            Abilities.
          </P>
          <Outlet />
        </Content>
      </ExamineeMenu>
    </>
  );
};

DiscoverComponent.propTypes = {
  abilities: PropTypes.shape({
    ability: PropTypes.arrayOf(PropTypes.object),
    career: PropTypes.arrayOf(PropTypes.object),
  }),
  activeProduct: PropTypes.object,
  /** The clusters ranked in order of results */
  rankedClusters: PropTypes.shape({
    ranked: PropTypes.array,
  }),
  /*
  // all the clusters in order of cluster ordering (1-14)
  allClusters: PropTypes.object,
  favoriteJobs,
  interests,
  loadClusters,
  loadResults,
  loading,
  toggleFavoriteJob,
  type,
  unloadResults,
  updateFavoriteJob,
  user,
  values,
  */
};

DiscoverComponent.defaultProps = {
  abilities: {},
  activeProduct: {},
  rankedClusters: [],
};

export default DiscoverComponent;
